import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import API from "../utils/API";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { MDXProvider, mdx } from "@mdx-js/react";
import { PRIMARY } from "../utils/colors";
import Button from "../components/shared/Button";
import JoinImage from "../images/JoinBg.png";
import Logo from "../components/shared/Logo";
import { navigate } from "gatsby";

const SignupContainerFlex = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 100px);
`;

const SignupContainer = styled.div`
  max-width: 350px;
  margin: auto;
  text-align: center;

  li {
    color: red;
    font-size: 14px;
  }

  input {
    height: 40px;
    padding: 4px;
    width: 100%;
  }

  label {
    display: block;
    font-size: 14px;
    margin-top: 8px;
    text-align: left;
    em {
      font-size: 12px;
    }
  }

  .actions {
    margin-top: 16px;
    text-align: center;

    button {
      width: 100%;
    }
  }
`;

const BgImg = styled.img`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.4;
`;

const SignupHeader = styled.h1`
  text-align: center;
  font-size: 40px;

  span {
    color: ${PRIMARY};
  }
`;

const IndexPage = () => {
  const [user, setUser] = useState({
    email: "",
    password: "",
    password_confirmation: "",
  });

  const [errors, setErrors] = useState([]);
  const { email, password, password_confirmation } = user;
  const loggedInUser = useSelector((state) => state.user.user);

  async function submit(e) {
    e.preventDefault();
    try {
      await API.post("/users", {
        user,
      });
      localStorage.setItem("fetch_user_info", JSON.stringify("true"));
      navigate("/curriculum", { state: { showEmailConfirmBanner: true } });
    } catch (e) {
      setErrors(
        e?.response?.data?.message?.split(",") || [
          "Something terribly wrong happened.",
        ]
      );
    }
  }

  function setEmail({ target: { value } }) {
    setUser({
      ...user,
      email: value,
    });
  }

  function setPassword({ target: { value } }) {
    setUser({
      ...user,
      password: value,
    });
  }

  function setPasswordConfirmation({ target: { value } }) {
    setUser({
      ...user,
      password_confirmation: value,
    });
  }

  useEffect(() => {
    if (loggedInUser) {
      window.location = "/curriculum";
    }
  }, [loggedInUser]);

  return (
    <Layout>
      <MDXProvider>
        <Seo title="Sign up" />
        <BgImg src={JoinImage} />
        <SignupContainerFlex>
          <SignupContainer>
            <>
              <Logo variant="primary" />
              <SignupHeader>
                {" "}
                Join thousands learning to build apps with <span>
                  {" "}
                  React{" "}
                </span>{" "}
              </SignupHeader>
              <p>
                Access our <b>private Discord community</b> when you create your
                account. Get help building your app and meet other React
                Developers around the world!
              </p>
              {errors.map((err) => (
                <li>{err}</li>
              ))}
              <form onSubmit={submit}>
                <div>
                  <label htmlFor="user_email">Email</label>
                  <input
                    autoFocus
                    autoComplete="email"
                    type="email"
                    value={email}
                    id="user_email"
                    onChange={setEmail}
                  />
                </div>

                <div className="field">
                  <label htmlFor="user_password">
                    Password <em>(6 characters minimum)</em>
                  </label>
                  <input
                    autoComplete="new-password"
                    type="password"
                    id="user_password"
                    value={password}
                    onChange={setPassword}
                  />
                </div>

                <div className="field">
                  <label htmlFor="user_password_confirmation">
                    Password confirmation
                  </label>
                  <input
                    autoComplete="new-password"
                    type="password"
                    id="user_password_confirmation"
                    value={password_confirmation}
                    onChange={setPasswordConfirmation}
                  />
                </div>

                <div className="actions">
                  <Button
                    type="submit"
                    name="commit"
                    data-disable-with="Sign up"
                  >
                    Create Account
                  </Button>
                  <a
                    style={{ fontSize: "12px" }}
                    href="https://api.react.school/users/password/new"
                  >
                    Reset your password
                  </a>
                </div>
              </form>
            </>
          </SignupContainer>
        </SignupContainerFlex>
      </MDXProvider>
    </Layout>
  );
};

export default IndexPage;
